import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelServices } from '../services/panel.service';
declare var jquery : any;
declare var $ : any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	created_date = '';
	created_time = '';
	list_main_category = '';
	latest_test_series = '';
	
	loader;
	auth_logn: any = '';

   constructor(private router: Router, public panelService: PanelServices) {}

	ngOnInit() {
		var d = new Date();
		var month = d.getMonth()+1;
		var day = d.getDate();

		this.created_date = d.getFullYear() + '-' + (month<10 ? '0' : '') + month + '-' + (day<10 ? '0' : '') + day;
		this.created_time = (d.getHours()<10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes()<10 ? '0' : '') + d.getMinutes() + ":" + (d.getSeconds()<10 ? '0' : '') + d.getSeconds();
		console.log('footer');
		
		
	}
	
	
   
}
