import { Injectable, EventEmitter } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams, Response, ResponseOptions } from '@angular/http';
import 'rxjs/Rx';
// import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import {
	MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition
} from '@angular/material';
import { Location } from '@angular/common';

@Injectable()
export class ApiServices {
	baseUrl = "https://api.postinfy.com";
	
	horizontalPosition: MatSnackBarHorizontalPosition = 'end';
	verticalPosition: MatSnackBarVerticalPosition = 'top';
	constructor(public http: Http, private snackBar: MatSnackBar, private location: Location) {}

	openSnackBar(msg: any, action: any, msgClass) {
		console.log(msgClass);
		this.snackBar.open(msg, action, {
			duration: 3000,
			horizontalPosition: this.horizontalPosition,
			verticalPosition: this.verticalPosition
		});
		$('.cdk-overlay-container').removeClass('errorMsg').removeClass('successMsg').addClass(msgClass);
	}
	
	
	handleError(err) {
		let responseOptions = new ResponseOptions({
			status: err.status,
			statusText: err.statusText
		});
		if (err.status == 500) {
			this.openSnackBar('Something went wrong', 'Ok', 'errorMsg');
			return Observable.throw(new Response(responseOptions));
		}
		if (err.status == 401) {
			this.openSnackBar('Unauthenticated', 'Ok', 'errorMsg');
			return Observable.throw(new Response(responseOptions));
		}
		return Observable.throw(err);
	}

	login(data) {
		let header = new Headers({ Accept: 'application/json' });
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(this.baseUrl + 'login', data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}

	addStaff(data) {
		let header = new Headers({
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('VeggmiAdminData')).token
		});
		let options = new RequestOptions({ headers: header });
		let httpParams = new URLSearchParams();
		httpParams.set('email', data.email);
		httpParams.set('role', data.role);
		httpParams.set('password', data.password);
		httpParams.set('name', data.name);
		return this.http
			.post(this.baseUrl + 'users', httpParams.toString(), options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}

	editStaff(data) {
		let header = new Headers({
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('VeggmiAdminData')).token
		});
		let options = new RequestOptions({ headers: header });
		let httpParams = new URLSearchParams();
		httpParams.set('name', data.name);
		httpParams.set('email', data.email);
		httpParams.set('role', data.role);
		httpParams.set('accessLevel', data.accessLevel);
		return this.http
			.patch(this.baseUrl + `users/${data._id}`, httpParams.toString(), options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}

	getStaff() {
		let header = new Headers({
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('VeggmiAdminData')).token
		});
		let httpParams = new URLSearchParams();
		httpParams.set('page', '1');
		httpParams.set('limit', '10');
		httpParams.set('sort', 'name');
		httpParams.set('filter', '{"role":"user"}');
		httpParams.set('order', '-1');
		let options = new RequestOptions({ headers: header, search: httpParams });
		return this.http
			.get(this.baseUrl + 'users', options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}

	getSystemAccess() {
		let header = new Headers({
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('VeggmiAdminData')).token
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.get(this.baseUrl + 'system/all', options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}

	editMyprofile(data) {
		let header = new Headers({
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('VeggmiAdminData')).token
		});
		let options = new RequestOptions({ headers: header });
		let httpParams = new URLSearchParams();
		httpParams.set('name', data.name);
		httpParams.set('email', data.email);
		httpParams.set('role', data.role);
		httpParams.set('accessLevel', data.accessLevel);
		return this.http
			.put(this.baseUrl + `users`, httpParams.toString(), options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
	getAddressSuggestion(values) {
		return this.http
			.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${values.address}&category=Address&countryCode=${values.country}&maxSuggestions=5&f=pjson`)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
	
	getAddressDetail(values) {
		return this.http
			.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?magicKey=${values.magicKey}&category=&outFields=*&forStorage=false&f=pjson`)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
	getPostalSuggestion(values) {
		return this.http
			.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${values.address}&category=Postal&countryCode=${values.country}&maxSuggestions=5&f=pjson`)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
	
	getPostalDetail(values) {
		return this.http
			.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?magicKey=${values.magicKey}&category=&outFields=*&forStorage=false&f=pjson`)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
}
