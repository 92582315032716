import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Http, Headers, RequestOptions, URLSearchParams, Response, ResponseOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import 'rxjs/Rx';
// import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs';
import {
	MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition
} from '@angular/material';
import { Location } from '@angular/common';
declare var Razorpay: any;
declare let document: any;

//@Injectable()
@Injectable({
  providedIn: 'root',
})
export class PanelServices {

	//baseUrl: string = 'http://localhost:3063/';  
	baseUrl: string = "https://api.postinfy.com/";  
	
	//web_base_url = 'http://localhost:4200/';
	web_base_url = 'https://app.postinfy.com/';
	
	//mainUrl = 'http://localhost/postinfyweb/';
	mainUrl = 'https://postinfy.com/';
	shareReferUrl = '';
	shareUrl;
	apiKey;
	userData = JSON.parse(localStorage.getItem('userData'));


	horizontalPosition: MatSnackBarHorizontalPosition = 'end';
	verticalPosition: MatSnackBarVerticalPosition = 'top';

	
	
	constructor(public http: Http,private https: HttpClient, public router: Router, private activatedRoute: ActivatedRoute,private snackBar: MatSnackBar, private location: Location) {}

	
	returnbaseUrl() {
		return this.baseUrl;
	}
	returnwebbaseUrl() {
		return this.web_base_url;
	}
	returnmainUrl() {
		return this.mainUrl;
	}
	openSnackBar(msg: any, action: any, msgClass) {
		console.log(msgClass);
		this.snackBar.open(msg, action, {
			duration: 3000,
			horizontalPosition: this.horizontalPosition,
			verticalPosition: this.verticalPosition
		});
		$('.cdk-overlay-container').removeClass('errorMsg').removeClass('successMsg').addClass(msgClass);
	}
	handleError(err) {
		console.log(err)

		let responseOptions = new ResponseOptions({
			status: err.status,
			statusText: err.statusText
		});
		if (err.status == 500) {
			//this.openSnackBar('Something went wrong', 'Ok', 'errorMsg');
			return Observable.throw(new Response(responseOptions));
		}
		if (err.status == 401) {
			//this.openSnackBar('Unauthenticated', 'Ok', 'errorMsg');
			return Observable.throw(new Response(responseOptions));
		}
		console.log(responseOptions)
		return Observable.throw(err);
	}

	
	upload_file(data) {
		console.log(data)
		var authorization_key = localStorage.getItem('p_w_token'); 
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => { 
				return this.handleError(err);
			});
	}
	
	getshippingServices(data) {
		//let apikey = 'key=' + environment.GMAP_KEY;
		//console.log(apikey);
		let parameters = '';
			for (const [key, value] of Object.entries(data)) {
			  parameters = parameters + `${key}=${value}&`; 
			}
		//parameters += apikey;
		const opts = { params: new HttpParams({ fromString: parameters }) };
		const _API = this.baseUrl+'website_api/v1/map_places';
		console.log(_API);
		console.log(opts);
		return this.https.get<any>(_API, opts);
	}

	getTravelTime(data) {
		//let apikey = 'key=' + environment.GMAP_KEY;
		let parameters = '';
		for (const [key, value] of Object.entries(data)) {
			parameters = parameters + `${key}=${value}&`;
		}
		//parameters += apikey;
		let opts = {
			params: new HttpParams({ fromString: parameters }),
		};
		const _API = this.baseUrl+'website_api/v1/traveltime';
		return this.https.get<any>(_API, opts); 
	}
	
	public upload_file_data(data): any {
		/*this.sellertoken = JSON.parse(localStorage.getItem('flatesellertoken'));
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const options = { headers: headersData };
		return this.httpClient.post<any>(this.baseUrl + 'sellero/upload_file_data', data, options);*/
	}
	comman_service_funcation(data) {
		console.log(data)
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP'
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => { 
				return this.handleError(err);
			});
		
	}
	comman_service_funcation_main_url(data) {
		console.log(data)
		let header = new Headers({
			Accept: 'application/json',
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(data.myurl, data, options).map((response: Response) => {
				return response.json();
			});
		
	}
	comman_service_funcation_with_get(data) {
		console.log(data)
		var authorization_key = localStorage.getItem('p_w_token'); 
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.get(data.myurl,  options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
		
	}
	comman_funnction_call_with_auth_with_put(data) {
		var authorization_key = localStorage.getItem('p_w_token'); 
		
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.put(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
	}
	comman_funnction_call_with_auth_with_put_with_temp(data) { 
		var authorization_key = localStorage.getItem('p_w_temperary'); 
		
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.put(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
	}
	
	comman_funnction_call_with_auth(data) {
		var authorization_key = localStorage.getItem('p_w_token'); 
		
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
	} 
	comman_funnction_call_with_auth_temp(data) {
		var authorization_key = localStorage.getItem('p_w_temperary'); 
		
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.post(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
	}
	comman_funnction_call_with_auth_temp_put(data) {
		var authorization_key = localStorage.getItem('p_w_temperary'); 
		
		let header = new Headers({
			Accept: 'application/json',
			security_key: 'POSTAPP',
			authorization_key:authorization_key
		});
		let options = new RequestOptions({ headers: header });
		return this.http
			.put(data.myurl, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return  this.handleError(err);
			});
	} 
	
	comman_service_funcation1(data) {
		
		let header = new Headers({
			Accept: 'application/json',
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('FeenuAdminData')).token
		});
		let options = new RequestOptions({ headers: header });
		//console.log(header);
		return this.http
			.post(this.baseUrl + data.apiname, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
		
	}
	comman_service_funcation_open(data) {
		let options = new RequestOptions();

		return this.http
			.post(this.baseUrl + data.apiname, data, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
	check_seller_auth() {
		var VeggmiSellerData = JSON.parse(localStorage.getItem('VeggmiSellerData'));
		return VeggmiSellerData;
	}
	logoutseller() {
		localStorage.setItem('VeggmiSellerData', JSON.stringify(''));
		var VeggmiSellerData = JSON.parse(localStorage.getItem('VeggmiSellerData'));
		return VeggmiSellerData;
	}

	check_admin_auth() {
		var VeggmiAdminData = JSON.parse(localStorage.getItem('VeggmiAdminData'));
		return VeggmiAdminData;
	}
	logoutAdmin() {
		localStorage.setItem('VeggmiAdminData', JSON.stringify(''));
		var VeggmiAdminData = JSON.parse(localStorage.getItem('VeggmiAdminData'));
		return VeggmiAdminData;
	}

	getReferCode() {
		console.log(this.userData);
		let length = Object.keys(this.userData).length;
		if (length != 0) {
			if (this.userData.refer_code) {
				return '/referred/' + this.userData.refer_code;
				//return '?refer_code='+this.userData.refer_code;
			} else {
				return '';
			}
		}
		return '';
	}
	openNativeShare(shareUrl) {
		let text = ``;

		if (this.userData.api_token) {
			let name = '';
			name = this.userData.first_name ? this.userData.first_name : '';
			name += this.userData.last_name ? this.userData.last_name : '';
			text = `Meet Shado - Your Personal Carbon Footprint ${name}@OnlyPlanet.in. I just did!`;
		} else {
			text = `@OnlyPlanet.in`;
		}

		let newVariable: any;
		newVariable = window.navigator;
		newVariable
			.share({
				title: '',
				text: text,
				url: shareUrl
			})
			.then((e) => {
				console.log('Successful share', e);
			})
			.catch((error) => console.log('Error sharing', error));
	}

	checkIfUserOnMobile(): boolean {
		if (navigator) {
			var ua = navigator.userAgent;
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
				// if website open in mobile
				return true;
			}
			return false;
		} else {
			return false;
		}
	}
 
	openShareWindowForWeb(action, shareUrl, video?) {
		console.log(shareUrl);
		let myUrl = '';
		switch (action) {
			case 'facebook':
				myUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
				break;
			case 'whatsapp':
				if (this.checkIfUserOnMobile()) {
					myUrl = 'whatsapp://send?text=';
				} else {
					myUrl = 'https://web.whatsapp.com/send?text=';
				}
				break;
			case 'googleplus':
				myUrl = 'https://plus.google.com/share?url=';
				break;
			case 'twitter':
				myUrl = 'https://twitter.com/intent/tweet?url=';
				break;
			case 'skype':
				myUrl = 'https://web.skype.com/share?url=';
				break;
			case 'linkedin':
				myUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=';
				break;
			case 'mail':
				myUrl = 'mailto:?body=';
				break;
			case 'pinterest':
				myUrl = 'http://pinterest.com/pinthis?url=';
				break;
		}
		if (video) {
			myUrl += video;
		} else {
			myUrl += shareUrl;
		}

		if (action == 'googleplus' || action == 'skype') {
			if (this.userData.api_token) {
				let name = '';
				name = this.userData.first_name ? this.userData.first_name : '';
				name += this.userData.last_name ? this.userData.last_name : '';
				myUrl += `&text=Meet Shado - Your Personal Carbon Footprint ${name}@Examswire.com. I just did!`;
			} else {
				myUrl += `&text=@Examswire.com`;
			}
		}

		let a = document.createElement('a') as HTMLAnchorElement;
		a.href = myUrl;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		// window.open(myUrl, '_blank');
		// shareWindow.focus();
	}

	addReward(data) {
		let header = new Headers({
			Accept: 'application/json'
		});
		let options = new RequestOptions({
			headers: header
		});
		let params = new URLSearchParams();
		params.append('api_token', this.apiKey);
		params.append('type', data.type);
		params.append('detail', data.detail);
		return this.http
			.post(this.baseUrl + `add-reward`, params, options)
			.map((response: Response) => {
				return response.json();
			})
			.catch((err) => {
				return this.handleError(err);
			});
	}
}
