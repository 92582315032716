import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { SidebarComponent } from "../sidebar/sidebar.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
@NgModule({
  declarations: [
    LoaderComponent,
    SidebarComponent,
    FooterComponent
  ],
  exports: [
    LoaderComponent,FormsModule, ReactiveFormsModule,ImageCropperModule, 
    SidebarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,ImageCropperModule,
    RouterModule,
    ReactiveFormsModule,AutoCompleteModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    TooltipModule.forRoot()
  ]
})
export class SharedModule { }
