import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PizzaService {
	
	baseUrl: string = 'http://localhost:3063/';  
	//baseUrl: string = "https://api.postinfy.com/";  
	
	constructor(private http: HttpClient) {}

	getPizzaStores(data) {
		let apikey = 'key=' + environment.GMAP_KEY;
		let parameters = '';
			for (const [key, value] of Object.entries(data)) {
			  parameters = parameters + `${key}=${value}&`;
			}
		parameters += apikey;
		const opts = { params: new HttpParams({ fromString: parameters }) };
		const _API = this.baseUrl+'map_places';
		return this.http.get<any>(_API, opts);
	}

	getTravelTime(data) {
		let apikey = 'key=' + environment.GMAP_KEY;
		let parameters = '';
		for (const [key, value] of Object.entries(data)) {
			parameters = parameters + `${key}=${value}&`;
		}
		parameters += apikey;
		let opts = {
			params: new HttpParams({ fromString: parameters }),
		};
		const _API = this.baseUrl+'traveltime';
		return this.http.get<any>(_API, opts);
	}
}
