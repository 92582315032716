import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,ActivatedRouteSnapshot } from '@angular/router';
import { PanelServices } from '../services/panel.service';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { ApiServices } from "../services/api.service";
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from "angular2-social-login";

declare var jquery : any;
declare var $ : any;
declare let swal: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	userData:any = {};
	temp_user_id=0;
	user_id=0;
	name= '';
	password= null;
	otp= null;
	reset_otp= null;
	mobile_num= null;
	email= '';
	Email= '';
	/*user profile*/
	p_name='';
	p_email='';
	p_email_old='';
	p_phone_number='';
	p_phone_number_old='';
	p_phone_number_show='';
	p_address='';
	p_address_line2='';
	p_country='United States';
	p_zip_code='';
	p_state='';
	p_city='';
	p_profile_pic='';
	p_otp=null;
	p_latitude=0;
	p_longitude=0; 
	imageObj: File;
	user_countrycode='US'; 
	usercountrycode_old=''; 
	email_verify=null;
	phone_verify=null;
	country_id=null;
	state_id=null;
	state_name='';
	state_data:any;
	city_data:any;
	islogin= 'yes';
	my_type='';
	changeText = '';
	usercountrycode = '';
	list_main_category = '';
	created_date = '';
	created_time = '';
	base_url = '';
	web_base_url = '';
	main_url = '';
	user_login_data = '';
	key='';
	reset_key='';
	resend_key='';
	resend_reset_key='';
	mobile_number:number;
	type=1;
	loader;
	card_details_data='';
	check_confirm=false;
	creat_password=null;
	creat_reset_password=null;
	confirm_password=null;
	confirm_reset_password=null;
	public suggestionData: { [key: string]: Object }[] = [];
	strength_percentage=0;
	reset_strength_percentage=0;
	card_number=null;
	card_expiry='';
	customer_name='';
	cvv=null;
	expiry_year_loop=[20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]
	card_expiry_mounth = '';
	card_expiry_year = '';
	//showPaaword = false;
	shipment_orders = '';
	password_str = '';
	password_strength = '';
	authorization_key = '';
	authorization_key_new = '';
	isphone = '';
	user_profile = '';
	user_name_first_letter = '';
	reward_points=null;
	email_or_phone = '';
	first_name = '';
	forgot_password_email_or_phone = '';
	email_or_phone_for_registration = '';
	//email_or_phone_for_registration_op = '';
	//password_matched=false;	
	p_address_autofill=false;
	is_user_has_pic = false;
	show_myshipment = false;
	show_shipapackage = false;
	show_homepage = false;
	is_social_or_not=false;
	
	//---------------------
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [
		CountryISO.UnitedStates
	];
	phoneForm = new FormGroup({
		phone: new FormControl('99', [Validators.required]),
	});
	forgot_pass_phoneForm = new FormGroup({
		forgot_pass_phone: new FormControl('99', [Validators.required]),
	});
	register_phoneForm = new FormGroup({
		register_phone: new FormControl('99', [Validators.required]),
	}); 
	register_op_phoneForm = new FormGroup({
		register_op_phone: new FormControl('99', [Validators.required]),
	}); 
	user_profile_phoneForm = new FormGroup({
		user_profile_phone: new FormControl('99', [Validators.required]),
	}); 
		
	constructor(private router: Router,public _auth: AuthService, private activatedRoute: ActivatedRoute , public panelService: PanelServices,private apiService:ApiServices) {
		
		this.base_url= this.panelService.returnbaseUrl();
		this.web_base_url= this.panelService.returnwebbaseUrl();
		this.main_url= this.panelService.returnmainUrl();
	}

	ngOnInit() {
		
		this.get_state_data();
		var p_w_user = localStorage.getItem('p_w_user');
		user_data = '';
		if(p_w_user){
			this.user_id = (JSON.parse(p_w_user).id);
			var user_data = (JSON.parse(p_w_user));
			
			
			var profile_pic = user_data.profile_pic ;
			var user_name = user_data.name ;
			var first_name = user_name.split(' ') ;
			this.first_name = first_name[0];
			console.log(profile_pic)
			
			var is_social_pic = profile_pic.split("https");
			console.log(is_social_pic);
			if(is_social_pic){
				if(is_social_pic.length>1){
					this.is_social_or_not=true;
				}else{
					this.is_social_or_not=false;
				}
			}
			if(profile_pic){
				this.user_profile = profile_pic ;
				this.is_user_has_pic = true;
			}else{
				this.is_user_has_pic = false;
				this.user_name_first_letter = user_name.charAt(0)
			}
		}
		//console.log(p_w_user)
		console.log(this.user_id)
		this.authorization_key = localStorage.getItem('p_w_token');
		if(this.authorization_key){
			console.log('yes')
		}else{
			console.log('no');
			this.authorization_key='';
		}
		console.log(this.authorization_key);
		var d = new Date();
		var month = d.getMonth()+1;
		var day = d.getDate();

		this.created_date = d.getFullYear() + '-' + (month<10 ? '0' : '') + month + '-' + (day<10 ? '0' : '') + day;
		this.created_time = (d.getHours()<10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes()<10 ? '0' : '') + d.getMinutes() + ":" + (d.getSeconds()<10 ? '0' : '') + d.getSeconds();
		//$('#myModal11').modal('show');
		
		console.log('user_id ; '+ this.user_id);
		console.log(this.p_phone_number)
		
		this.loader = true;
		var comman_data = {
			authorization_key: this.authorization_key,
			myurl: this.main_url+'api/account/is_user_login'
		}
		this.panelService.comman_service_funcation_main_url(comman_data).subscribe(data => {
			console.log(data);
			if(data.status == 405){
				localStorage.setItem('p_w_token', '');
				this.show_shipapackage = false;
				this.user_profile = '';
				this.user_name_first_letter = '';
				this.first_name = '';
				this.authorization_key ='';
			
			}else{
				this.loader = false;
				if(data.user_id){
					if(jQuery.type( data.user_id) == 'object'){
						if(data.user_id.body.length == 0){
							localStorage.setItem('p_w_token', '');
							this.show_shipapackage = false;
							this.user_profile = '';
							this.user_name_first_letter = '';
							this.first_name = '';
							this.authorization_key ='';
						}
					}
				}
			}
		});
		
		setInterval(() => {
			var current_page = localStorage.getItem('p_w_current_page');
			//console.log(current_page);
			if(current_page == 'shipapackage'){
				this.show_myshipment = true;
				this.show_homepage = true;
				this.show_shipapackage = false;
			}else if(current_page == 'myshipment'){
				this.show_myshipment = false;
				this.show_shipapackage = true;
				this.show_homepage = true;
			}else{
				this.show_myshipment = true;
				this.show_shipapackage = true;
				this.show_homepage = false;
			}
			
			if(!current_page){
				console.log(this.show_homepage);
				this.show_homepage = true;
				this.show_shipapackage = true;
			}
		}, 500); 
	} 
	myaccountModal(){
		this.get_user_login_detail();
		$('#myModal11').modal('show');
		$('.profile_tabs').addClass('display_none');
		$('.profile_class').removeClass('display_none');
		$('.profile_modal_list').removeClass('active');
		$('#profile_li').addClass('active');
	}
	open_ship_a_package(){
		this.router.navigate(['ship-a-package']);
	}
	open_myshipment(){
		this.router.navigate(['my-shipment']);
	}
	signIn(provider){
		console.log(provider);
		var mythins = this;


		console.log(provider);
		this._auth.login(provider).subscribe(
			(data) => {
				console.log(data);
				var id_google = '';
				var id_facebook = '';
				var social_id = '';
				var social_type = 1;
				if(provider == 'facebook'){
					social_id =  data['uid'];social_type = 2;
				}else{
					social_id = data['uid'];social_type = 1;
				}
				var name = data['name'];
				var email = data['email'];
				var profile_pic = data['image'];
				var email_verify = 1;
				var authorization_key = '';
				this.email_or_phone_for_registration = 'email';
				
				this.loader = true;
				var comman_data = {
					name:name,
					email:email,
					profile_pic:profile_pic,
					email_verify:email_verify,
					social_id:social_id,
					email_or_phone:'email',
					social_type:social_type,
					device_type:'',
					device_token:'',
					myurl: this.base_url+'api/v1/soical_login'
				}
				this.panelService.comman_service_funcation(comman_data)
					.subscribe( data => {
							console.log(data);
						$('#myModal').modal('hide');
						this.loader = false;
						if(data.code == 200){
							console.log(data);
							var mythins = this;
							mythins.authorization_key = data.body.authorization_key;
							localStorage.setItem('p_w_token', data.body.authorization_key);
							console.log(localStorage.getItem('p_w_token'));
							localStorage.setItem('p_w_user', JSON.stringify(data.body));
							$('.after_login_ship_a_package').trigger('click');
							$('.success_msg').removeClass('display_none').text(data.message);
							$(".success_msg").fadeTo(2000, 500).slideUp(500, function(){
								$('.success_msg').empty();
							});
							var mythins = this;
							location.reload();
							setTimeout(function(){console.log('2');
								mythins.ngOnInit();
							}, 500);
							
						}else{
							$('.error_msg').removeClass('display_none').text(data.message);
						}
					},err => {
						this.loader = false;
						console.log('HTTP Error', err)
						$('.error_msg').removeClass('display_none').text('Invalid login credentials');
						$(".error_msg").fadeTo(2000, 500).slideUp(500, function(){
							$('.error_msg').empty();
						});
					}
				);
				
			},
			(error) => {
				console.log(error);
			}
		)
		
	}
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
	register_user_reset_value(){
		console.log('register_user_reset_value')
		this.key='';
		this.reset_key='';
		this.name= '';
		this.password= null;
		this.otp= null;
		this.reset_otp= null;
		this.mobile_num= null;
		this.email= '';
		this.Email= '';
		this.confirm_password= '';
		this.creat_password= '';
		this.password_str = '';
		this.password_strength = '';
		this.creat_reset_password='';
		this.confirm_reset_password='';
		console.log(this.user_countrycode);
		this.user_countrycode='US'; 
		this.check_confirm=false;
		console.log(this.user_countrycode);
		setTimeout(function(){
			this.user_countrycode='US'; 
		console.log(this.user_countrycode);
		}, 500);
		$('#creat_password').attr('type','password');
		$('#confirm_password').attr('type','password');
		$('.error_div').addClass('display_none');
		$('.password_match_').addClass('display_none');
	}
	on_shift_register(){ 
		this.register_user_reset_value();
		console.log('1');
		$('#myModal').modal('hide');
		setTimeout(function(){console.log('2');
			$('#myModal2').modal('show');
		}, 500);
	}
	on_shift_signin(){ 
		$('#myModal2').modal('hide');
		setTimeout(function(){console.log('2');
			$('#myModal').modal('show');
		}, 500);
	}
	on_shift_sign_in(){ 
		$('#myModal8').modal('hide');
		setTimeout(function(){console.log('1');
			$('#myModal').modal('show');
		}, 500);
	}
	on_shift_reset_password(){ 
		this.reset_key='';
		this.reset_otp=null;
		this.forgot_password_email_or_phone = '';
		$('#myModal').modal('hide');
		setTimeout(function(){console.log('8');
			$('#myModal8').modal('show');
		}, 500);
	}
	
	onLogout() {
		console.log('onLogout');
		
		localStorage.setItem("p_w_token", '');
		$('.after_logout_ship_a_package').trigger('click');
		//this.ngOnInit();   
		location.reload()
	}
	onLogout_from_profile() {
		console.log('onLogout');
		$('#myModal11').modal('hide');
		localStorage.setItem("p_w_token", '');
		this.router.navigate(['ship-a-package']);
		
		setTimeout(function(){
			$('.after_logout_ship_a_package').trigger('click');
		}, 500);
		//this.ngOnInit();   
		//location.reload()
	}
	toggleShowpasswordtype(input: any, image:any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
		image.src= image.src === this.web_base_url+'assets/images/eye-sharp.svg' ? this.web_base_url+'assets/images/eye-off-sharp.svg' : this.web_base_url+'assets/images/eye-sharp.svg';
	}
	toggle_create_password(input: any, image:any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
		image.src = image.src === this.web_base_url+'assets/images/eye-sharp.svg' ? this.web_base_url+'assets/images/eye-off-sharp.svg' : this.web_base_url+'assets/images/eye-sharp.svg';
	}
	toggle_create_confirm_password(input: any, image:any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
		image.src= image.src === this.web_base_url+'assets/images/eye-sharp.svg' ? this.web_base_url+'assets/images/eye-off-sharp.svg' : this.web_base_url+'assets/images/eye-sharp.svg';
	}
	toggle_forgot_create_password(input: any, image:any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
		image.src= image.src === this.web_base_url+'assets/images/eye-sharp.svg' ? this.web_base_url+'assets/images/eye-off-sharp.svg' : this.web_base_url+'assets/images/eye-sharp.svg';
	}
	toggle_forgot_confirm_password(input: any, image:any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
		image.src= image.src === this.web_base_url+'assets/images/eye-sharp.svg' ? this.web_base_url+'assets/images/eye-off-sharp.svg' : this.web_base_url+'assets/images/eye-sharp.svg';
	}
	check_email_or_phone(){ 
		if(this.email){
			if($.isNumeric(this.email)){
				if(this.email.length>4){
					this.email_or_phone = 'phone';
				}else{
					this.email_or_phone = 'email';
				}
			}else{
				this.email_or_phone = 'email';
			}
		}else{
			this.email_or_phone = '';
		}
	}
	check_email_or_phone_forgot_password(){
		if(this.reset_key){
			if($.isNumeric(this.reset_key)){
				if(this.reset_key.length>4){
					this.forgot_password_email_or_phone = 'phone';
				}else{
					this.forgot_password_email_or_phone = 'email';
				}
			}else{
				this.forgot_password_email_or_phone = 'email';
			}
		}else{
			this.forgot_password_email_or_phone = '';
		}
	}
	check_email_or_phone_for_registration(){
		if(this.key){
			if($.isNumeric(this.key)){
				if(this.key.length>4){
					this.email_or_phone_for_registration = 'phone';
				}else{
					this.email_or_phone_for_registration = 'email';
				}
			}else{
				this.email_or_phone_for_registration = 'email';
			}
		}else{
			this.email_or_phone_for_registration = '';
		}
	}
	
	resetLoginModal(){
		$('.error_div').addClass('display_none');
		this.email='';
		this.password='';
	}
	signin_button(){
		console.log(this.phoneForm.value);
		console.log(this.phoneForm.value.phone);
		console.log(this.phoneForm.value.phone.dialCode);
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.email){
			$('.phone_email_error').removeClass('display_none').text('Phone or Email Address is required.');
			iserror = 'yes';
		}else{
			if($.isNumeric(this.email)){
				if(this.email.length<9){
					$('.phone_email_error').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
				if(this.email.length>12){
					$('.phone_email_error').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
			}else{
				var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
				if((!EMAIL_REGEXP.test(this.email))){
					$('.phone_email_error').removeClass('display_none').text('Email not valid');
					iserror = 'yes';
				}
			}
		}
		if(!this.password){
			$('.password_error').removeClass('display_none').text('Password field is required.');
			iserror = 'yes';
		}else if(this.password.length <7){
			$('.password_error').removeClass('display_none').text('Password must be minimum 8 digits long.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
			console.log('call api');
			var phone_or_email ='';
			var check_type = 0;
			if($.isNumeric(this.email)){
				phone_or_email=this.phoneForm.value.phone.dialCode + this.email;
				check_type = 1;
			}
			else{
				check_type = 2;
				phone_or_email=this.email;
			}
			this.loader = true;
			var comman_data = {
				check_type:check_type,
				email:phone_or_email,
				password:this.password,
				myurl: this.base_url+'website_api/v1/login'
			}
			this.panelService.comman_service_funcation(comman_data)
				.subscribe( data => {
						console.log(data);
					
					this.loader = false;
					if(data.code == 200){
						$('#myModal').modal('hide');
						console.log(data);
						$('.success_msg').removeClass('display_none').text(data.message);
						$(".success_msg").fadeTo(2000, 500).slideUp(500, function(){
							$('.success_msg').empty();
						});
						localStorage.setItem('p_w_token', data.body.authorization_key);
						localStorage.setItem('p_w_user', JSON.stringify(data.body));
						this.ngOnInit();
						$('.after_login_ship_a_package').trigger('click');
					}else{
						$('.error_msg').removeClass('display_none').text(data.message);
						$(".error_msg").fadeTo(2000, 5000).slideUp(500, function(){
							$('.error_msg').empty();
							//this.email_or_phone='';
							//this.email='';
							//this.password='';
						});
					}
				},err => {
					this.loader = false;
					console.log('HTTP Error', err)
					$('.error_msg').removeClass('display_none').text('Wrong Login Credentials.');
					$(".error_msg").fadeTo(2000, 2000).slideUp(500, function(){
						$('.error_msg').empty();
					});
				}
			);
		}
	}
	create_account_phone_or_email(){
		console.log(this.register_phoneForm.value);
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.key){
			$('.phone_or_email_error').removeClass('display_none').text('Phone or Email Address is required.');
			iserror = 'yes';
		}else{
			if($.isNumeric(this.key)){
				if(this.key.length<9){
					$('.phone_or_email_error').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
				if(this.key.length>12){
					$('.phone_email_error').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
			}else{
				var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
				if((!EMAIL_REGEXP.test(this.key))){
					$('.phone_or_email_error').removeClass('display_none').text('Email not valid');
					iserror = 'yes';
				}
			}
			if(iserror == 'no'){
				console.log('call api');
					var phone_or_email_registeration ='';
					var country_code = this.register_phoneForm.value.register_phone.countryCode;
					var keyy = this.key;
					if($.isNumeric(this.key)){
						phone_or_email_registeration=this.register_phoneForm.value.register_phone.dialCode + this.key;
						this.resend_key = this.register_phoneForm.value.register_phone.dialCode + this.key;
					}
					else{
						phone_or_email_registeration=this.key;
						this.resend_key = this.key;
						country_code= '';
						keyy= '';
					}
					var mytype = '';
					if(this.key.indexOf("@") > -1){
						this.type=2;mytype = '2';
						
					}else{
						this.type=1;mytype = '1';
					}
					console.log(this.type)
					this.loader = true;
					var comman_data = {
						key:phone_or_email_registeration,
						type:this.type,
						phone_number:keyy,
						country_code:country_code,
						myurl: this.base_url+'website_api/v1/checkEmailOrPhone'
					}
					this.panelService.comman_funnction_call_with_auth_temp(comman_data)
						.subscribe( data => {
							this.loader = false;
							localStorage.setItem('p_w_temperary', data.body.authorization_key); 
							localStorage.setItem('p_w_type',mytype); 
							if(data.code == 200){
								var time_now_ = Date.now();
								console.log(time_now_);
								localStorage.setItem('p_w_otp_sent_time',JSON.stringify(time_now_)); 
								console.log('sucess')
								console.log(data.body)
								$('#myModal2').modal('hide');
								setTimeout(function(){console.log('2');
									$('#myModal3').modal('show');
								}, 500);
							}
						},err => {
							this.loader = false;
							console.log('otp Error')
							$('.error_msg21').removeClass('display_none').text('The Email/Phone is already registered. Kindly use another.');
							$(".error_msg21").fadeTo(2000, 500).slideUp(500, function(){
								$('.error_msg21').empty();
							});
						}						
					);
				
			}
		}
		
	}
	resend_password_otp(){
		this.loader = true;
		var comman_data = {
			key:this.resend_key,
			type:this.type,
			myurl: this.main_url+'api/account/resendOtp'
		}
		this.panelService.comman_service_funcation_main_url(comman_data)
			.subscribe( data => {
			this.loader = false;
			if(data.status == 200){
				var time_noww = Date.now();
				console.log(time_noww);
				localStorage.setItem('p_w_otp_sent_time',JSON.stringify(time_noww)); 
				console.log('sucess')
				console.log(data.body)
			}
		});	
	}
	submit_otp_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.otp){
			$('.otp_error').removeClass('display_none').text('Please enter OTP.');
			iserror = 'yes';
		}if(this.otp.length<6){
			$('.otp_error').removeClass('display_none').text('Please enter all 6 digits.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
			var otp_time_ = parseInt(JSON.parse(localStorage.getItem('p_w_otp_sent_time')));
			console.log(otp_time_)
			if(otp_time_+600000 < Date.now()){
				$('.wrong_otp').removeClass('display_none').text('Otp was valid for 10 minutes.Kindly Press on resend otp and get new one.');
				$(".wrong_otp").fadeTo(2000, 500).slideUp(500, function(){
					$('.wrong_otp').empty();
				});
			}else{
				console.log('call api');
				this.loader = true;
				var comman_data = {
					otp:this.otp,
					myurl: this.base_url+'website_api/v1/verifyOtp'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
						this.loader = false;
						if(data.code == 200){
							this.temp_user_id = data.body.id;
							console.log('temp_user_id: '+this.temp_user_id);
							console.log('sucess')
							console.log(data.body)
							$('#myModal2').modal('hide');
							$('#myModal3').modal('hide');
							setTimeout(function(){console.log('3');
								$('#myModal4').modal('show');
							}, 500);
							$('.otp_verifyed_').removeClass('display_none').text(data.message); 
							$(".otp_verifyed_").fadeTo(2000, 500).slideUp(500, function(){
								$('.otp_verifyed_').empty();
							});
						}else {
							$('.wrong_otp_').removeClass('display_none').text(data.message);
							$(".wrong_otp_").fadeTo(2000, 500).slideUp(500, function(){
								$('.wrong_otp_').empty();
							});
						}
					},err => {
						this.loader = false;
						console.log('otp Error')
						$('.wrong_otp_').removeClass('display_none').text('Verification code does not match');
						$(".wrong_otp_").fadeTo(2000, 500).slideUp(500, function(){
							$('.wrong_otp_').empty();
						});
					}
				);
				
			}
		}
	}
	
	password_change_event(){
		var pass = this.creat_password;
		var strength = 1;
		var arr = [/.{8,}/, /[a-z]+/, /[0-9]+/, /[A-Z]+/];
		jQuery.map(arr, function(regexp) {
			if(pass.match(regexp))
			strength++;
		});
		if(strength == 2){
			this.password_str = 'Weak';
			this.strength_percentage = 25;
			
		}else if(strength == 3){
			this.password_str = 'Fair';
			this.strength_percentage = 50;
			
		}else if(strength == 4){
			this.password_str = 'Good';
			this.strength_percentage = 75;
			
		}else if(strength == 5){
			this.password_str = 'Strong';
			this.strength_percentage = 100;
		} 
		console.log(strength)
		
		
	}
	check_confirm_password(){
		$('.message_div').addClass('display_none');
		if(this.confirm_password == this.creat_password){
			$('.password_match_').removeClass('display_none')
			$('.password_match_error').addClass('display_none')
		}
	}
	submit_password_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.creat_password){
			$('.password_error').removeClass('display_none').text('Password is required.');
			iserror = 'yes';
		}else if(this.creat_password.length<8){
			$('.password_length_error_').removeClass('display_none').text('Password must be minimum 8 characters long..');
			iserror = 'yes';
		}
		if(!this.confirm_password){
			$('.confirm_password_error').removeClass('display_none').text('Password Confirm is required.');
			iserror = 'yes';
		}
		else if(this.confirm_password!=this.creat_password){
			$('.password_match_error').removeClass('display_none').text('Password Confirm does not match.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
			console.log('call api');
			this.my_type = localStorage.getItem('p_w_type');
			console.log(this.my_type);
				this.loader = true;
				var comman_data = {
					password:this.creat_password,
					myurl: this.base_url+'website_api/v1/updatePassword'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						console.log(data.body)
						if(this.my_type=='2'){
							$('#myModal4').modal('hide');
							setTimeout(function(){console.log('5');
								$('#myModal5').modal('show');
							}, 500);
						}
						if(this.my_type=='1'){
							$('#myModal4').modal('hide');
							setTimeout(function(){console.log('6');
								$('#myModal6').modal('show');
							}, 500);
						}
					}
				});
		}
	}
	submit_mobile_number_button(){
		console.log(this.register_op_phoneForm);
		console.log(this.register_op_phoneForm.value.register_op_phone.dialCode);
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(this.mobile_num){
			if(this.mobile_num.length<9){
				$('.phone_number_error').removeClass('display_none').text('Phone number not valid.');
				iserror = 'yes';
			}
			if(this.mobile_num.length>12){
					$('.phone_or_email_error_').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
			if(iserror == 'no'){
				console.log('call api');
				this.loader = true;
				var phone_or_email_forgot_pass=this.register_op_phoneForm.value.register_op_phone.dialCode + this.mobile_num;
					
				var comman_data = {
					key:phone_or_email_forgot_pass,
					type: 1,
					myurl: this.base_url+'website_api/v1/updateEmailOrPhone'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						console.log(data.body)
						this.key='';
						$('#myModal5').modal('hide');
						setTimeout(function(){console.log('7');
							$('#myModal7').modal('show');
						}, 500);
					}else{
						this.loader = false;
						$('.error_msg_phone_already_exist').removeClass('display_none').text(data.error_message);
						$(".error_msg_phone_already_exist").fadeTo(2000, 500).slideUp(500, function(){
							$('.error_msg_phone_already_exist').empty();
						});
					}	
				},err => {
					this.loader = false;
					$('.error_msg_phone_already_exist').removeClass('display_none').text('This Phone is already registered. Kindly use another.');
					$(".error_msg_phone_already_exist").fadeTo(2000, 500).slideUp(500, function(){
						$('.error_msg_phone_already_exist').empty();
					});
				});		
				
				
			}
		}else{
			$('#myModal5').modal('hide');
			setTimeout(function(){console.log('7');
				$('#myModal7').modal('show');
			}, 500);
		}
	}
	submit_email_address_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(this.Email){
			var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
			if((!EMAIL_REGEXP.test(this.Email))){
				$('.email_error').removeClass('display_none').text('Email not valid.');
				iserror = 'yes';
			}
			if(iserror == 'no'){
				console.log('call api');
				this.loader = true;
				var comman_data = {
					key:this.Email,
					type: 2,
					myurl: this.base_url+'website_api/v1/updateEmailOrPhone'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						this.key='';
						console.log(data.body)
						$('#myModal6').modal('hide');
						setTimeout(function(){console.log('7');
							$('#myModal7').modal('show');
						}, 500);
					}else{
						this.loader = false;
						$('.error_msg_email_already_exist').removeClass('display_none').text(data.error_message);
						$(".error_msg_email_already_exist").fadeTo(2000, 500).slideUp(500, function(){
							$('.error_msg_email_already_exist').empty();
						});
					}	
				},err => {
					this.loader = false;
					$('.error_msg_email_already_exist').removeClass('display_none').text('The Email is already registered. Kindly use another.');
					$(".error_msg_email_already_exist").fadeTo(2000, 500).slideUp(500, function(){
						$('.error_msg_email_already_exist').empty();
					});
				});	
				
			}
		}else{
			$('#myModal6').modal('hide');
			setTimeout(function(){console.log('7');
				$('#myModal7').modal('show');
			}, 500);
		}
	}
	submit_user_name_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.name){
			$('.user_name_error').removeClass('display_none').text('User name is required.');
			iserror = 'yes';
		}
		if(!this.check_confirm){
			$('.checkbox_error').removeClass('display_none').text('Check the checkbox.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
				console.log('call api');
				this.loader = true;
				var comman_data = {
					check_confirm:this.check_confirm,
					name:this.name,
					myurl: this.base_url+'website_api/v1/updateProfile'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						$('#myModal7').modal('hide');
							setTimeout(function(){console.log('home page');
						}, 500);
						
						localStorage.setItem('p_w_user', JSON.stringify(data.body));
						localStorage.setItem('p_w_token', data.body.authorization_key);
						
						console.log(localStorage.getItem('p_w_token'));
						$('.after_login_ship_a_package').trigger('click');
							
						this.user_id = this.temp_user_id;
						console.log('temp_user_id ; '+ this.temp_user_id);
						console.log('user_id ; '+ this.user_id);
						this.ngOnInit();
						
					}	
				});	
			}
	}
	reset_forgot_password(){
		console.log(this.forgot_pass_phoneForm.value);
		console.log(this.forgot_pass_phoneForm.value.forgot_pass_phone);
		console.log(this.forgot_pass_phoneForm.value.forgot_pass_phone.dialCode);
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.reset_key){
			$('.phone_or_email_error_').removeClass('display_none').text('Phone or Email Address is required.');
			iserror = 'yes';
		}else{
			if($.isNumeric(this.reset_key)){
				if(this.reset_key.length<9){
					$('.phone_or_email_error_').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
				if(this.reset_key.length>12){
					$('.phone_or_email_error_').removeClass('display_none').text('Phone number not valid.');
					iserror = 'yes';
				}
			}else{
				var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
				if((!EMAIL_REGEXP.test(this.reset_key))){
					$('.phone_or_email_error_').removeClass('display_none').text('Email not valid');
					iserror = 'yes';
				}
			}
			if(iserror == 'no'){
				console.log('call api');
					var mytype = '';
					if(this.reset_key.indexOf("@") > -1){
						this.type=2;mytype = '2';
						
					}else{
						this.type=1;mytype = '1';
					}
					console.log(this.type)
					this.loader = true;
					var phone_or_email_forgot_pass ='';
					
					if($.isNumeric(this.reset_key)){
						phone_or_email_forgot_pass=this.forgot_pass_phoneForm.value.forgot_pass_phone.dialCode + this.reset_key;
						 this.resend_reset_key = phone_or_email_forgot_pass;
					}
					else{
						phone_or_email_forgot_pass=this.reset_key;
						this.resend_reset_key = this.reset_key;
					}
					var comman_data = {
						key:phone_or_email_forgot_pass,
						user_id:this.user_id,
						type:this.type,
						myurl: this.main_url+'api/account/forgot_password'
					}
					this.panelService.comman_service_funcation_main_url(comman_data)
						.subscribe( data => {
						this.loader = false;
						console.log(data)
							if(data.status == 200){
								this.authorization_key_new = data.authorization_key;
								var time_now = Date.now();
								console.log(time_now);
								localStorage.setItem('p_w_otp_valid',JSON.stringify(time_now)); 
								console.log('sucess')
								console.log(data.body)
								//localStorage.setItem('p_w_temperary', data.body.authorization_key); 
								$('#myModal8').modal('hide');
								setTimeout(function(){console.log('9');
									$('#myModal9').modal('show');
								}, 500);
							}else{
								$('.error_msg_phone_or_otp').removeClass('display_none').text(data.message);
								$(".error_msg_phone_or_otp").fadeTo(2000, 500).slideUp(500, function(){
								$('.error_msg_phone_or_otp').empty();
							});
							}	  
						},err => {
							this.loader = false;
							$('.error_msg_phone_or_otp').removeClass('display_none').text('Email or Phone not Registered.');
							$(".error_msg_phone_or_otp").fadeTo(2000, 500).slideUp(500, function(){
								$('.error_msg_phone_or_otp').empty();
							});
						}
					);
				
			}
		}
	}	
	resend_reset_password_otp(){
		this.loader = true;
		var comman_data = {
			key:this.resend_reset_key,
			type:this.type,
			myurl: this.main_url+'api/account/resendOtp_forgot'
		}
		this.panelService.comman_service_funcation_main_url(comman_data)
			.subscribe( data => {
			this.loader = false;
			if(data.status == 200){
				var time__now = Date.now();
				console.log(time__now);
				localStorage.setItem('p_w_otp_valid',JSON.stringify(time__now)); 
				console.log('sucess')
				//console.log(data.body)
			}
		});	
	}
	resend_update_phone_email_otp(type){
		console.log(type);
		var key = '';
		if(type == 1){
			key = this.user_profile_phoneForm.value.user_profile_phone.dialCode + this.p_phone_number;
		}else{
			key = this.p_email;
		}
		console.log(key);
		this.loader = true;
		var comman_data = {
			user_id:this.user_id,
			key:key,
			type:type,
			myurl: this.main_url+'api/account/resend_profile_Otp'
		}
		this.panelService.comman_service_funcation_main_url(comman_data)
			.subscribe( data => {
			this.loader = false;
			if(data.status == 200){
				console.log(data.body)
			}
		},err => {
			this.loader = false;
			$('.otp_error_msg').removeClass('display_none').text('Something Went Wrong');
			$(".otp_error_msg").fadeTo(2000, 500).slideUp(500, function(){
				$('.otp_error_msg').empty();
			});
		});	
	}
	submit_reset_otp_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.reset_otp){
			$('.reset_otp_error').removeClass('display_none').text('Please enter OTP.');
			iserror = 'yes';
		}if(this.reset_otp.length<6){
			$('.reset_otp_error').removeClass('display_none').text('Please enter all 6 digits.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
			var otp_time = parseInt(JSON.parse(localStorage.getItem('p_w_otp_valid')));
			console.log(otp_time)
			if(otp_time+600000 < Date.now()){
				$('.wrong_otp').removeClass('display_none').text('Otp was valid for 10 minutes.Kindly Press on resend otp and get new one.');
				$(".wrong_otp").fadeTo(2000, 500).slideUp(500, function(){
					$('.wrong_otp').empty();
				});
			}else{
				console.log('call api');
				this.loader = true;
				
				var comman_data = {
					key:this.resend_reset_key,
					type:this.type,
					otp:this.reset_otp,
					myurl: this.main_url+'api/account/verifyOtp'
				}
				this.panelService.comman_service_funcation_main_url(comman_data)
					.subscribe( data => {	
						this.loader = false;
						if(data.status == 200){
							this.creat_reset_password='';
							this.confirm_reset_password='';
							$('.password_match').addClass('display_none');
							console.log('sucess')
							console.log(data.body)
							$('.otp_verifyed_').removeClass('display_none').text(data.message); 
							$(".otp_verifyed_").fadeTo(2000, 500).slideUp(500, function(){
								$('.otp_verifyed_').empty();
							});
							$('#myModal9').modal('hide');
							setTimeout(function(){console.log('10');
								$('#myModal10').modal('show');
							}, 500);
						}else {
							$('.wrong_otp').removeClass('display_none').text('Verification code does not match');
							$(".wrong_otp").fadeTo(2000, 500).slideUp(500, function(){
								$('.wrong_otp').empty();
							});
						}
					},err => {
						this.loader = false;
						console.log('otp Error')
						$('.wrong_otp').removeClass('display_none').text('Verification code does not match');
						$(".wrong_otp").fadeTo(2000, 500).slideUp(500, function(){
							$('.wrong_otp').empty();
						});
					}
				);
			}	
		}
	}
	reset_password_change_event(){
		var passs = this.creat_reset_password;
		var strength_reset = 1;
		var arrr = [/.{8,}/, /[a-z]+/, /[0-9]+/, /[A-Z]+/];
		jQuery.map(arrr, function(regexp) {
			if(passs.match(regexp))
			strength_reset++;
		});
		if(strength_reset == 2){
			this.password_strength = 'Weak';
			this.reset_strength_percentage = 25;
			
		}else if(strength_reset == 3){
			this.password_strength = 'Fair';
			this.reset_strength_percentage = 50;
			
		}else if(strength_reset == 4){
			this.password_strength = 'Good';
			this.reset_strength_percentage = 75;
			
		}else if(strength_reset == 5){
			this.password_strength = 'Strong';
			this.reset_strength_percentage = 100;
		} 
		console.log(strength_reset)
		
		
	}
	reset_check_confirm_password(){
		$('.message_div').addClass('display_none');
		if(this.confirm_reset_password == this.creat_reset_password){
			$('.password_match').removeClass('display_none')
			$('.password_match_error').addClass('display_none')
		}
	}
	submit_reset_password_button(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		if(!this.creat_reset_password){
			$('.password_error').removeClass('display_none').text('Password is required.');
			iserror = 'yes';
		}else if(this.creat_reset_password.length<8){
			$('.password_length_error').removeClass('display_none').text('Password must be minimum 8 characters long..');
			iserror = 'yes';
		}
		if(!this.confirm_reset_password){
			$('.confirm_password_error').removeClass('display_none').text('Password Confirm is required.');
			iserror = 'yes';
		}
		else if(this.confirm_reset_password!=this.creat_reset_password){
			$('.password_match_error').removeClass('display_none').text('Password Confirm does not match.');
			iserror = 'yes';
		}
		if(iserror == 'no'){
			console.log('call api');
			this.my_type = localStorage.getItem('p_w_type');
			console.log(this.my_type);
				this.loader = true;
				var comman_data = {
					authorization_key:this.authorization_key_new,
					password:this.creat_reset_password,
					myurl: this.base_url+'website_api/v1/updatePassword'
				}
				this.panelService.comman_funnction_call_with_auth_temp_put(comman_data)
					.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						console.log(data.body)
						$('#myModal10').modal('hide');
						setTimeout(function(){console.log('home');
							$('#myModal').modal('show');
						}, 500);
					}
				});
		}
	}
	profile_tab_button(){
		$('.profile_modal_list').removeClass('active');
		$('#profile_li').addClass('active');
		$('.profile_tabs').addClass('display_none');
		$('.profile_class').removeClass('display_none');
	}
	rewards_tab_button(){
		this.get_all_order_list();
		$('.profile_modal_list').removeClass('active');
		$('#rewards_li').addClass('active');
		$('.profile_tabs').addClass('display_none');
		$('.rewards_class').removeClass('display_none');
	}
	payments_tab_button(){
		this.get_all_card_details();
		$('.profile_modal_list').removeClass('active');
		$('#payments_li').addClass('active');
		$('.profile_tabs').addClass('display_none');
		$('.add_card_').addClass('display_none');
		$('.card_list_div').removeClass('display_none');
		$('.payments_class').removeClass('display_none');
	}
	settings_tab_button(){
		$('.profile_modal_list').removeClass('active');
		$('#settings_li').addClass('active');
		$('.profile_tabs').addClass('display_none');
		$('.setting_class').removeClass('display_none');
		$('.user_phone_no').removeClass('display_none');
		$('.user_phone_otp').addClass('display_none');
		this.p_otp = null;
		this.p_phone_number = this.p_phone_number_old ;
		if(!this.usercountrycode_old.length){
			this.usercountrycode = '';
			this.usercountrycode = 'US';
		}else{
			this.usercountrycode = this.usercountrycode_old ;
		}
		this.p_email = this.p_email_old ;
	}
	logout_tab_button(){
		$('.profile_modal_list').removeClass('active');
		$('#logout_li').addClass('active');
		$('.profile_tabs').addClass('display_none')
		$('.logout_class').removeClass('display_none');
	}
	open_add_card_Section(){
		$('.error_div').addClass('display_none');
		$('.card_list_div').addClass('display_none');
		$('.add_card_').removeClass('display_none');
	}
	open_card_list(){
		$('.add_card_').addClass('display_none');
		$('.card_list_div').removeClass('display_none');
	}
	get_user_login_detail(){
		this.loader = true;
		var comman_data = {
			myurl: this.base_url+'website_api/v1/get_rewards/'+this.user_id
		}
		this.panelService.comman_service_funcation_with_get(comman_data)
			.subscribe( data => {
				this.loader = false;
				if(data.code == 200){
					localStorage.setItem('p_w_user', JSON.stringify(data.body));
					this.user_login_data =data.body;
					console.log(this.user_login_data)
					
					this.p_name=this.user_login_data['name'];
					this.p_profile_pic=this.user_login_data['profile_pic'];
					console.log(this.p_profile_pic);
					this.p_email=this.user_login_data['email'];
					this.p_email_old=this.user_login_data['email'];
					this.p_phone_number_show=this.user_login_data['phone'];
					console.log(this.p_phone_number_show)
					this.p_phone_number=this.user_login_data['phone_number'];
					this.p_phone_number_old=this.user_login_data['phone_number'];
					this.usercountrycode=this.user_login_data['country_code'];
					this.usercountrycode_old=this.user_login_data['country_code'];
					if(!this.usercountrycode){
						this.usercountrycode='US';
					}
					this.p_address=this.user_login_data['address'];
					this.p_address_line2=this.user_login_data['address_line_2'];
					//this.p_country=this.user_login_data['country'];
					this.p_zip_code=this.user_login_data['zip_code'];
					this.state_name=this.user_login_data['state'];
					this.p_city=this.user_login_data['city'];
					this.email_verify=this.user_login_data['email_verify'];
					this.phone_verify=this.user_login_data['phone_verify'];
					this.reward_points=this.user_login_data['reward_points'];
					
					var first_name = this.p_name.split(' ') ;
					this.first_name = first_name[0];
					this.user_name_first_letter = this.p_name.charAt(0)
				}
			},err => {
				this.loader = false;
				localStorage.setItem("p_w_token", '');
				this.ngOnInit();
				
			}
		);		
	}
	
	
	/*state_change_event(){
		var stateList = this.state_data.find(s => s.code == this.p_state);
		console.log(stateList.code);
		this.state_name = (stateList.code)
		this.state_id = (stateList.id)
		this.get_city_data(this.state_id);	
	}*/
	
	get_state_data(){
		this.loader = true;
		var comman_data = {
			country_id: 230,
			myurl: this.base_url+'website_api/v1/getStates'
		}
		this.panelService.comman_service_funcation(comman_data)
			.subscribe( data => {
				this.loader = false;
				if(data.code == 200){
					this.state_data=data.body;
					console.log(this.state_data)
				}	
		});
	}
	get_city_data(state_id){
		this.loader = true;
		var comman_data = {
			state:this.state_name,
			state_id: this.state_id,
			myurl: this.base_url+'website_api/v1/getCities'
		}
		this.panelService.comman_service_funcation(comman_data)
			.subscribe( data => {
				this.loader = false;
				if(data.code == 200){
					this.city_data=data.body;
					console.log(this.city_data)
				}	
		});
	}
	
	state_change_event_only_for_name(){
		var ListItemSimplified = this.state_data.find(s => s.code == this.p_state);
		console.log(ListItemSimplified);
		this.state_name = (ListItemSimplified.name)
		console.log(this.state_name)
		this.state_id = (ListItemSimplified.id);
	}
	valueChange(ev:FilteringEventArgs){
		let req_data={
			address:ev.text,
			country:'US'
		}
		this.apiService.getAddressSuggestion(req_data)
			.subscribe( data => {
				if(data.suggestions && data.suggestions.length > 0){
					ev.updateData(data.suggestions); 
				}
		});
		ev.preventDefaultAction=true;
	}

	selectedAddress(ev){
		console.log(ev)
		if(ev.itemData && ev.itemData.magicKey){
			this.apiService.getAddressDetail(ev.itemData)
			.subscribe( data => {
				if(data){
					console.log(data);
					console.log(data['candidates']);
					console.log(data['candidates'][0]['attributes']['RegionAbbr']);
					this.p_state = data['candidates'][0]['attributes']['RegionAbbr'];
					this.state_name = data['candidates'][0]['attributes']['Region'];
					this.p_address = data['candidates'][0]['attributes']['ShortLabel'];
					this.p_address_line2 = data['candidates'][0]['attributes']['Nbrhd']+ ' '+data['candidates'][0]['attributes']['District'];
					//console.log(this.p_address_line2);
					this.p_zip_code = data['candidates'][0]['attributes']['Postal'];
					//this.state_change_event_only_for_name();
					this.p_latitude = data['candidates'][0]['location']['y'];
					this.p_longitude = data['candidates'][0]['location']['x'];
					var mydata = {"id":0,"state_id":this.state_id,"country_id":230,"latitude":data['candidates'][0]['location']['y'],"longitude":data['candidates'][0]['location']['x'],"name":data['candidates'][0]['attributes']['City']};
					var mydataararay = [];
					mydataararay.push(mydata);
					console.log(mydataararay);
					this.city_data=mydataararay;
					this.p_city = data['candidates'][0]['attributes']['City'];
					this.p_address_autofill = true;
				}
			});
		}
	}
	selectedZip(ev){
		console.log(ev)
		if(ev.itemData && ev.itemData.magicKey){
			this.apiService.getPostalDetail(ev.itemData)
			.subscribe( data => {
				if(data){
					console.log(data);
					console.log(data['candidates']);
					console.log(data['candidates'][0]['attributes']['RegionAbbr']);
					this.p_state = data['candidates'][0]['attributes']['RegionAbbr'];
					this.state_name = data['candidates'][0]['attributes']['Region'];
					//this.p_address = data['candidates'][0]['attributes']['ShortLabel'];
					this.p_zip_code = data['candidates'][0]['attributes']['Postal'];
					var PostalExt = data['candidates'][0]['attributes']['PostalExt'];
					if(PostalExt){
						this.p_zip_code = this.p_zip_code +'-'+ PostalExt;
					}
					//this.state_change_event_only_for_name();
					this.p_latitude = data['candidates'][0]['location']['y'];
					this.p_longitude = data['candidates'][0]['location']['x'];
					var mydata = {"id":0,"state_id":this.state_id,"country_id":230,"latitude":data['candidates'][0]['location']['y'],"longitude":data['candidates'][0]['location']['x'],"name":data['candidates'][0]['attributes']['City']};
					var mydataararay = [];
					mydataararay.push(mydata);
					console.log(mydataararay);
					this.city_data=mydataararay;
					this.p_city = data['candidates'][0]['attributes']['City'];
					this.p_address_autofill = true;
				}
			});
		}
	}
	valueChangeZip(ev:FilteringEventArgs){
		console.log(this.usercountrycode);
		console.log(ev);
		console.log(ev.text);
		let req_data={
			address:ev.text,
			country:this.usercountrycode
		}
		this.apiService.getPostalSuggestion(req_data)
			.subscribe( data => {
				if(data.suggestions && data.suggestions.length > 0){
					ev.updateData(data.suggestions); 
				}
		});
		ev.preventDefaultAction=true;
	}
	update_user_profile(){
		this.loader = true;
		var comman_data = {
			address:this.p_address,
			address_line_2:this.p_address_line2,
			city: this.p_city,
			country: this.p_country,
			name: this.p_name,
			profile_pic: this.p_profile_pic,
			state: this.state_name,
			zip_code: this.p_zip_code,
			myurl: this.base_url+'website_api/v1/updateProfile'
		}
		this.panelService.comman_funnction_call_with_auth_with_put(comman_data)
			.subscribe( data => {
				this.loader = false;
				if(data.code == 200){
					console.log(data.body)
					this.get_user_login_detail();
					$('.update_sucess_message').removeClass('display_none').text(data.message);
					$(".update_sucess_message").fadeTo(2000, 500).slideUp(500, function(){
						$('.update_sucess_message').empty();
					});
				}else{
					$('.update_error_msg').removeClass('display_none').text(data.message);
					$(".update_error_msg").fadeTo(2000, 500).slideUp(500, function(){
						$('.update_error_msg').empty();
					});
				}	
		});
	}
	get_all_order_list(){
		this.loader = true;
		var comman_data = {
			limit: 5,
			page: 1,
			myurl: this.base_url+'api/v1/get_all_orders'
		}
		this.panelService.comman_funnction_call_with_auth(comman_data)
			.subscribe( data => {
				this.loader = false;
				if(data.code == 200){
					console.log(data.body)
					this.shipment_orders=data.body.order_details;
					console.log(this.shipment_orders)
					
				}	
			},err => {
				this.loader = false;
				console.log('HTTP Error', err)
				$('.error_msg').removeClass('display_none').text('Unaturization');
				$('#myModal11').modal('hide');
				localStorage.setItem("p_w_token", '');
				this.router.navigate(['/']);
			}
		);
	}
	submit_user_profile_phone(){
		if(this.p_phone_number_old !=this.p_phone_number){
			var iserror = 'no';
			$('.error_div').addClass('display_none');
			if(!this.p_phone_number){
				$('.p_phone_error').removeClass('display_none').text('Phone Number is required.');
				iserror = 'yes';
			}
			if(iserror == 'no'){
				this.loader = true;
				console.log(this.user_profile_phoneForm.value.user_profile_phone.dialCode);
				var user_phone_num=this.user_profile_phoneForm.value.user_profile_phone.dialCode + this.p_phone_number;
				var comman_data = {
					country_code:this.user_profile_phoneForm.value.user_profile_phone.dialCode,
					phone_number:this.p_phone_number,
					phone:user_phone_num,
					myurl: this.base_url+'website_api/v1/checkPhone'
				}
				this.panelService.comman_funnction_call_with_auth_with_put(comman_data)
					.subscribe( data => {
						this.loader = false;
						$('.otp_sent_sucess').addClass('display_none')
						$('.otp_error_msg').addClass('display_none')
						if(data.code == 200){
							console.log(data.body)
							$('.user_phone_no').addClass('display_none')
							$('.user_phone_otp').removeClass('display_none')
							$('.otp_sent_sucess').removeClass('display_none').text(data.message)
							$(".otp_sent_sucess").fadeTo(2000, 500).slideUp(500, function(){
								$('.otp_sent_sucess').empty();
							});
						}	
					},err => {
						this.loader = false;
						console.log('HTTP Error', err)
						$('.otp_error_msg').removeClass('display_none').text('The Phone Number is already registered. Kindly use another');
						$(".otp_error_msg").fadeTo(2000, 500).slideUp(500, function(){
						$('.otp_error_msg').empty();
						});	
					}
				);
			}
		}
	}
	submit_phone_otp(){
		console.log(this.user_profile_phoneForm.value.user_profile_phone.countryCode);
		var countryCode=this.user_profile_phoneForm.value.user_profile_phone.countryCode;
		this.loader = true;
		var authorization_key = localStorage.getItem('p_w_token'); 
		var user_phone_num=this.user_profile_phoneForm.value.user_profile_phone.dialCode + this.p_phone_number;
		var comman_data = {
			country_code:countryCode,  
			phone_number:this.p_phone_number,
			phone:user_phone_num,
			phone_otp:this.p_otp,
			authorization_key:authorization_key,
			myurl: this.main_url+'api/configure/submitphoneotp'
		}	
		this.panelService.comman_service_funcation_main_url(comman_data)
		.subscribe( data => {
			this.loader = false;
			$('.otp_sent_sucess').addClass('display_none')
			$('.otp_error_msg').addClass('display_none')
			if(data.status == 200){
				console.log(data.body)
				$('.otp_sent_sucess').removeClass('display_none').text(data.message)
				$(".otp_sent_sucess").fadeTo(2000, 500).slideUp(500, function(){
					$('.otp_sent_sucess').empty();
				});
				$('.setting_class').addClass('display_none')
				$('.profile_class').removeClass('display_none')
				$('.profile_modal_list').removeClass('active');
				$('#profile_li').addClass('active');
				this.get_user_login_detail();
				this.p_otp=null;
			}else{
				$('.otp_error_msg').removeClass('display_none').text('Invalid OTP')
				$(".otp_error_msg").fadeTo(2000, 500).slideUp(500, function(){
					$('.otp_error_msg').empty();
				});
			}	
		});	
		
	}
	submit_email_otp(){
		this.loader = true;
		var authorization_key = localStorage.getItem('p_w_token'); 
		var comman_data = {
			email:this.p_email,
			email_otp:this.p_otp,
			authorization_key:authorization_key,
			myurl: this.main_url+'api/configure/submitemailotp'
		}	
		this.panelService.comman_service_funcation_main_url(comman_data)
		.subscribe( data => {
			this.loader = false;
			$('.otp_sent_sucess').addClass('display_none')
			$('.otp_error_msg').addClass('display_none')
			if(data.status == 200){
				console.log(data.body)
				$('.otp_sent_sucess').removeClass('display_none').text(data.message)
				$(".otp_sent_sucess").fadeTo(2000, 500).slideUp(500, function(){
					$('.otp_sent_sucess').empty();
				});
				$('.setting_class').addClass('display_none')
				$('.profile_class').removeClass('display_none')
				$('.profile_modal_list').removeClass('active');
				$('#profile_li').addClass('active');
				this.get_user_login_detail();
				this.p_otp=null;
			}else{
				$('.otp_error_msg').removeClass('display_none').text('Invalid OTP')
				$(".otp_error_msg").fadeTo(2000, 500).slideUp(500, function(){
					$('.otp_error_msg').empty();
				});
			}	
		});	
		
	}
	back_button_for_phone_number(){   
		$('.user_phone_otp').addClass('display_none')
		$('.user_phone_no').removeClass('display_none')
		this.p_otp=null;
		this.p_phone_number = this.p_phone_number_old ;
		
		if(!this.usercountrycode_old.length){
			console.log('usus')
			this.usercountrycode = '';
			this.usercountrycode = 'US';
			console.log(this.usercountrycode)
		}else{
			this.usercountrycode = this.usercountrycode_old ;
		}
	}
	back_button_for_email(){   
		$('.user_email_otp').addClass('display_none')
		$('.user_email_address').removeClass('display_none')
		this.p_otp=null;
		this.p_email = this.p_email_old ;
	}
	colaps_expand(){
		if($('.arrows').hasClass('settings_chevron_down')){
			$('.arrows').removeClass('settings_chevron_down').removeClass('fa-chevron-down')
			$('.arrows').addClass('settings_chevron_up').addClass('fa-chevron-up')
		}else{
			$('.arrows').removeClass('settings_chevron_up').removeClass('fa-chevron-up')
			$('.arrows').addClass('settings_chevron_down').addClass('fa-chevron-down')
		}
	}
	email_colaps_expand(){
		if($('.e_arrows').hasClass('e_settings_chevron_down')){
			$('.e_arrows').removeClass('e_settings_chevron_down').removeClass('fa-chevron-down')
			$('.e_arrows').addClass('e_settings_chevron_up').addClass('fa-chevron-up')
		}else{
			$('.e_arrows').removeClass('e_settings_chevron_up').removeClass('fa-chevron-up')
			$('.e_arrows').addClass('e_settings_chevron_down').addClass('fa-chevron-down')
		}
	}
	submit_user_profile_email(){
		if(this.p_email_old !=this.p_email){
			var iserror = 'no';
			$('.errror_div').addClass('display_none');
			if(!this.p_email){
				$('.p_email_error').removeClass('display_none').text('Email is required.');
				iserror = 'yes';
			}
			if(iserror == 'no'){ 
				this.loader = true;
				var comman_data = {
					email:this.p_email,
					myurl: this.base_url+'website_api/v1/checkEmail'
				}
				this.panelService.comman_funnction_call_with_auth_with_put(comman_data)
					.subscribe( data => {
						this.loader = false;
						$('.otp_sent_sucess').addClass('display_none')
						$('.otp_error_msg').addClass('display_none')
						if(data.code == 200){
							console.log(data.body)
							$('.user_email_address').addClass('display_none')
							$('.user_email_otp').removeClass('display_none')
							$('.otp_sent_sucess').removeClass('display_none').text(data.message)
							$(".otp_sent_sucess").fadeTo(2000, 500).slideUp(500, function(){
								$('.otp_sent_sucess').empty();
							});
						}	
					},err => {
						this.loader = false;
						console.log('HTTP Error', err)
						$('.otp_error_msg').removeClass('display_none').text('The Email is already registered. Kindly use another');
						$(".otp_error_msg").fadeTo(2000, 500).slideUp(500, function(){
							$('.otp_error_msg').empty();
						});
					}
				);
			}
		}
	}
	get_all_card_details(){
		var url = this.base_url+'api/v1/get_cards';
		this.loader = true;
		var comman_data = {
			myurl: url
		}
		this.panelService.comman_service_funcation_with_get(comman_data)
			.subscribe( data => {
			this.loader = false;
			if(data.code == 200){
				console.log('okok')
				this.card_details_data=data.body;
				console.log(this.card_details_data);
			}	
		});
	}
	GetCardType() {
		var number = String(this.card_number);
		// visa
		var re = new RegExp("^4");
		if (number.match(re) != null)
			return "Visa";

		// Mastercard 
		// Updated for Mastercard 2017 BINs expansion
		 if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
			return "Mastercard";

		// AMEX
		re = new RegExp("^3[47]");
		if (number.match(re) != null)
			return "AMEX";

		// Discover
		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if (number.match(re) != null)
			return "Discover";

		// Diners
		re = new RegExp("^36");
		if (number.match(re) != null)
			return "Diners";

		// Diners - Carte Blanche
		re = new RegExp("^30[0-5]");
		if (number.match(re) != null)
			return "Diners - Carte Blanche";

		// JCB
		re = new RegExp("^35(2[89]|[3-8][0-9])");
		if (number.match(re) != null)
			return "JCB";

		// Visa Electron
		re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
		if (number.match(re) != null)
			return "Visa Electron";

		return "";
	}
	add_card_detail_buttton(){
		var iserror = 'no';
		$('.error_div').addClass('display_none');
		var card_type = '';
		if(!this.card_number){
			$('.card_number_error').removeClass('display_none').text('Card number is required.');
			iserror = 'yes';			
		}else{
			card_type = this.GetCardType();
			if(!card_type){
				$('.card_number_error').removeClass('display_none').text('Invalid card number.');
				iserror = 'yes';			
			}
		}
		if(!this.card_expiry_mounth){
			$('.card_expiry_month_error').removeClass('display_none').text('Card expiry month is required.');
			iserror = 'yes';
		}
		if(!this.card_expiry_year){
			$('.card_expiry_year_error').removeClass('display_none').text('Card expiry year is required.');
			iserror = 'yes';
		}
		if(!this.cvv){
			$('.card_cvv_error').removeClass('display_none').text('Please enter only 3 digits for CVV.');
			iserror = 'yes';
		}
		if(!this.customer_name){
			$('.card_holder_error').removeClass('display_none').text('Carder Holder Name is required');
			iserror = 'yes';
		}
		if(iserror == 'no' ){
			var url = this.base_url+'website_api/v1/add_card';
			this.loader = true;
			var card_number_ = String(this.card_number);
			console.log(card_number_);
			var card_number_ = card_number_.split(" ").join("")
			console.log(card_number_);
			var card_number_new = parseInt(card_number_);
			console.log(card_number_new);
			var formatted_card_number_array = card_number_.match(/.{1,4}/g)
			var formatted_card_number = formatted_card_number_array[3];
			var comman_data = {
				card_number:card_number_new,
				holder_name:this.customer_name,
				expiry_month:this.card_expiry_mounth,
				expiry_year:this.card_expiry_year,
				formatted_card_number: formatted_card_number,
				card_type: card_type,
				myurl: url
			}
			this.panelService.comman_funnction_call_with_auth(comman_data)
				.subscribe( data => {
					this.loader = false;
					if(data.code == 200){
						this.card_number='';
						this.customer_name = '';
						this.card_expiry_mounth = '';
						this.card_expiry_year = '';
						this.cvv = '';
						console.log('okok')      
						this.get_all_card_details();
						$('.add_card_').addClass('display_none');
						$('.card_list_div').removeClass('display_none');
					}	
				},err =>{
					this.loader = false;
					$('.card_error').removeClass('display_none').text('Invalid card details');
					$(".card_error").fadeTo(2000, 500).slideUp(500, function(){
						$('.card_error').empty();
					});
				});
		}
	}
	card_keyup(){ 
		if(this.card_number){
			console.log(this.card_number);
			var card_number = ""+this.card_number+"";
			var card_number = card_number.replace(/\s/g,'')
			console.log(card_number);
			var card_number_array = (card_number.match(/.{1,4}/g));
			
			if(card_number_array[3] && card_number_array[2] && card_number_array[1] && card_number_array[0]){
				this.card_number = card_number_array[0] + ' ' +card_number_array[1] + ' ' +card_number_array[2] + ' ' +card_number_array[3] ;
			}else if(card_number_array[2] && card_number_array[1] && card_number_array[0]){
				this.card_number = card_number_array[0] + ' ' +card_number_array[1] + ' ' +card_number_array[2];
			}else if(card_number_array[1] && card_number_array[0]){
				this.card_number = card_number_array[0] + ' ' +card_number_array[1];
			}else if(card_number_array[0]){
				this.card_number = card_number_array[0];
			}
		}
	}
	delete_card_for_payment(data){
		console.log(data);
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',width: 500,padding: '20px',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#fff',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				var card_id = (data.id);
				var url = this.base_url+'api/v1/delete_card';
				this.loader = true;
				var comman_data = {
					card_id: card_id,
					myurl: url
				}
				this.panelService.comman_funnction_call_with_auth(comman_data)
					.subscribe( data => {
						this.loader = false;
						if(data.code == 200){
							Swal.fire({
								icon: 'success',showConfirmButton: false,
								title: 'success!',timer: 2000,
								text: "Deleted successfully"
							})
							this.get_all_card_details();
						}	
				});
				
			}
		});
	}
	upload_file(){
		$(".change_file_upload").trigger("click");
	}
	selectFile(event: Event){
		console.log(event)
		console.log((event.target as HTMLInputElement).files[0])
		const FILE = (event.target as HTMLInputElement).files[0];
		console.log(FILE)
		if(FILE.type == "image/png" || FILE.type == "image/jpeg" || FILE.type == "image/jpg" || FILE.type == "image/gif"){
			this.getBase64(FILE, (result) => {
				this.imageObj = result;
				this.upload();
			});
		}else{
			$('.update_error_msg').removeClass('display_none').text('File type not allowed.');
			$(".update_error_msg").fadeTo(5000, 500).slideUp(500, function(){
				$('.update_error_msg').empty();
			});
		}
	}
	
	getBase64(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}
	upload() {
		this.loader = true;
		const imageForm = new FormData();
		imageForm.append('image', this.imageObj);
		console.log(this.imageObj)
		//console.log(imageForm)
		var url = this.main_url+'api/account/update_profile_pic';
		var comman_data = {
			base64: this.imageObj, 
			user_id: this.user_id,
			myurl: url
		}	
		this.panelService.comman_service_funcation_main_url(comman_data)
		.subscribe( data => {
			console.log(data);
			this.loader = false;
			if(data.status == 200){
				console.log(data.new_img)
				this.p_profile_pic = data.new_img;
				this.user_profile = data.new_img;
				this.is_user_has_pic = true;
				if(this.is_social_or_not){
					this.is_social_or_not=false;
				}
				this.get_user_login_detail();
			}else{
				
			}	
		});	
		
	}
}                                         
