import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PizzatrackerComponent } from './pizzatracker/pizzatracker.component';


const routes: Routes = [
	{ path:"pizza",component:PizzatrackerComponent},
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.homeModule) },
	{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.homeModule) },
	{ path: 'security-and-privacy-policy', loadChildren: () => import('./publiccontent/privacy.module').then(m => m.privacyModule) },
	{ path: 'about-us', loadChildren: () => import('./publiccontent/aboutus.module').then(m => m.aboutusModule) },
	{ path: 'shipping-carriers', loadChildren: () =>import('./publiccontent/shippingcarriers.module').then(m => m.shippingcarriersModule) },
	{ path: 'terms-and-conditions',loadChildren: () => import('./publiccontent/terms.module').then(m => m.termsModule) },
	{ path: 'help-and-support', loadChildren: () => import('./publiccontent/faqs.module').then(m => m.faqsModule)},
	{ path: 'contact-us', loadChildren: () => import('./publiccontent/contactus.module').then(m => m.contactusModule)},
	{ path: 'ship-a-package', loadChildren: () => import('./shipapackage/shipapackage.module').then(m => m.shipapackageModule)},
	{ path: 'my-shipment', loadChildren: () => import('./myshipment/myshipment.module').then(m => m.myshipmentModule)},
	{ path: 'cookies-policy', loadChildren: () => import('./publiccontent/cookies_policy.module').then(m => m.cookies_policyModule) },
	{ path: 'printlabel', loadChildren: () => import('./printlabel/printlabel.module').then(m => m.printlabelModule) }
];
   
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
