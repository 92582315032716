import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { PanelServices } from "./services/panel.service";
import { SharedModule } from "./shared/shared.module";
import { ApiServices } from "./services/api.service";

import { MetaModule } from "ng2-meta";
import { MetaGuard } from "ng2-meta";
//import { AgmCoreModule } from "@agm/core";     // required for pizza-tracking
//import { AgmDirectionModule } from "agm-direction";  // required for pizza-tracking
//import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { Angular2SocialLoginModule } from "angular2-social-login";
let providers = {
  google: {
    clientId:
      "939568368493-vark8vb18eminvn1fr7p7a6al1rorphm.apps.googleusercontent.com",
  },
  linkedin: {
    clientId: "81rmmgvpkkl19k",
  },
  facebook: {
    clientId: "1377409985728800",
    apiVersion: "v3.3", //like v2.4
  },
};
/*334885056977637*/

import {
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatDialogModule,
  MatFormFieldModule,
} from "@angular/material"; /**/
import * as $ from "jquery";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PizzatrackerComponent } from "./pizzatracker/pizzatracker.component";
import { PizzaService } from "./services/pizza.service";

@NgModule({
  declarations: [AppComponent,PizzatrackerComponent],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSnackBarModule,
    //GooglePlaceModule,
    MatDialogModule,
    MatFormFieldModule,
    HttpModule,
    MetaModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    Angular2SocialLoginModule,
    /*AgmCoreModule.forRoot({
      apiKey: environment.GMAP_KEY,
    }),
    AgmDirectionModule,  // required for pizza-tracking*/
  ],
  providers: [PanelServices, ApiServices, PizzaService],
  bootstrap: [AppComponent],
})
export class AppModule {}

Angular2SocialLoginModule.loadProvidersScripts(providers);
